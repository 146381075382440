<template>
    <div class="wraper-root">
        <Carousel class="banner-section w-ba" :autoplay="2000" :wrap-around="true">
            <Slide v-for="slide in banners" :key="slide">
                <img class="banner-bg" :src="slide.imgURL" />
            </Slide>
        </Carousel>
        <main class="page-content w-ba" id="games-section">
            <section class="toggle-section">
                <div class="container">
                    <div class="main-tab">
                        <a href="javascript:void(0)"  @click="$store.commit('changeTab', 'casino')">
                            <div class="box-bg">
                                <div class="text_box left">
                                    <div class="desc">
                                        <h3>{{ langweb("fe.livecasino") }}</h3>
                                        <span>세계 최고의 카지노 게임들을 만나보세요.</span>
                                    </div>
                                    <img class="main-icon left" src="../assets/img/elements/casino-icon.png" alt="">
                                </div>
                                <img src="../assets/img/elements/casino-girls.png" alt="">
                            </div>
                        </a>
                        <a href="javascript:void(0)" @click="$store.commit('changeTab', 'slot')">
                            <div class="box-bg">
                                <div class="text_box right">
                                    <div class="desc">
                                        <h3>{{ langweb("fe.slotgame") }}</h3>
                                        <span>최고의 슬롯 게임에서 잭팟을 도전하세요.</span>
                                    </div>
                                    <img class="main-icon right" src="../assets/img/elements/slot-icon.png" alt="">
                                </div>
                                <img src="../assets/img/elements/slot-chars.png" alt="">
                            </div>
                        </a>
                    </div> 
                </div>
                <div class="tab-content-toogle game-list">
                    <section class="game-provider">
                        <div class="container">
                            <div class="tab1" v-if="gameTab === 'casino'">
                                <div class="casino-section sc-section">
                                    <div class="w-100 game-card-parent casino">
                                        <a href="javascript:void(0)" v-for="(item, index) in listGameCasino" :key="index" class="game-card animate__animated animate__fadeIn">
                                            <div class="game-card-body">
                                                <img :src="item.strImage" alt="">                                                
                                                <div class="game-card-overlay">
                                                    <div class="game-title">
                                                        <div class="card-logo-wrapper">
                                                            <!-- <img :src="item.image2" alt=""> -->
                                                            <img :src="item.strImage"  alt="">
                                                        </div>
                                                        <h6>{{item.title}}</h6>
                                                    </div>
                                                    <button type="button" class=""  v-if="token === null" @click="showModal = true">
                                                        <i class="fa-solid fa-play"></i>
                                                    </button>
                                                    <button type="button" class="" v-else-if="item.maintenance == 1" @click="getMaintenance()">
                                                        <i class="fa-solid fa-play"></i>
                                                    </button>
                                                    <button type="button" class="" v-else @click=" getGameProcess(item.vender,item.code,1)">
                                                        <i class="fa-solid fa-play"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="tab2" v-if="gameTab === 'slot'">
                                <div class="slot-section sc-section active">
                                    <div class="game-card-parent slot flex-wrap w-100">
                                        <a href="javascript:void(0)" v-for="(item, index) in listGameSlot" :key="index" class="game-card animate__animated animate__fadeIn">
                                            <div class="game-card-body">                                                
                                                <img :src="item.strImage"  alt="">
                                                
                                                <div class="game-card-overlay">
                                                    <div class="game-title">
                                                        <!-- <div class="card-logo-wrapper"> -->
                                                            <!-- <img :src="item.image2" alt=""> -->
                                                            <!-- <img src="../assets/img/logo/redgold.png" alt="">
                                                        </div> -->
                                                        <h6>{{item.title}}</h6>
                                                    </div>
                                                    <button type="button" class="" v-if="token === null" @click="showModal = true">
                                                        <i class="fa-solid fa-play"></i>
                                                    </button>
                                                    <button type="button" class="" v-else-if="item.maintenance == 1" @click="getMaintenance()">
                                                        <i class="fa-solid fa-play"></i>
                                                    </button>
                                                    <button type="button" class="fa-solid fa-play" :data_name="item.title" :data_id="item.code" v-else @click="showItemGame">                                                      
                                                    </button>
                                                    
                                                </div>
                                            </div>  
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section class="contact-section">
                <div class="container">
                    <div>
                        <!-- <img src="../assets/img/icons/telegram.svg" alt=""> -->
                    </div>
                </div>
            </section>
            <input type="hidden" name="datacode" class="datacode" value="0" />
            <div
                id="loading_show"
                class="loading_show"
                style="
                    position: fixed;
                    left: 0;
                    bottom: 0;
                    top: 0%;
                    right: 0%;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.5);
                    z-index: 999999999;
                "
            >
                <div class="windows8">
                    <img src="../assets/img/icons/loader.svg" alt="" />
                </div>
            </div>
            <section class="board-section w-ba">
                <div class="container max-width-gib dflex-ac-jc">
                    <div class="board-panel event">
                        <div class="header dflex-ac-jc w-b">
                            <div class="title-panel dflex-ac-jc mr-auto">
                                <div class="icon-panel dflex-ac-jc">
                                    <img
                                        src="../assets/img/icons/faq-icon.png"
                                    />
                                </div>
                                <span>{{ langweb("fe.faq") }}</span>
                            </div>
                            <button data_id="12" @click="showMain" class="more-btn event-link dflex-ac-jc">전체보기</button>
                        </div>
                        <div class="content w-b btm-event-section">
                            <table class="w-100">
                                <tbody>
                                    <tr
                                        v-for="(item, index) in listFaq.slice(
                                            0,
                                            5
                                        )"
                                        v-bind:key="index"
                                    >
                                        <td class="text-left">
                                            <a
                                                href="javascript:void(0);"
                                                v-if="token !== null"
                                                class="notifyhome_clicks"
                                                >{{
                                                    item.title.substring(0, 25)
                                                }}</a
                                            >
                                            <a
                                                href="javascript:void(0);"
                                                v-else
                                                @click="showModal = true"
                                                >{{
                                                    item.title.substring(0, 25)
                                                }}</a
                                            >
                                            <div
                                                class="substring_content_tbss hideppss"
                                                style="color: #fff"
                                            >
                                                <div class="flex_popup">
                                                    <div class="showtb">
                                                        <div
                                                            class="height_scroll"
                                                        >
                                                            <p
                                                                class="titlenoti"
                                                            >
                                                                {{ item.title }}
                                                            </p>
                                                            <div
                                                                v-html="
                                                                    item.content
                                                                "
                                                                class="content_tb"
                                                            ></div>
                                                        </div>
                                                        <button
                                                            class="close_notiss"
                                                            type="button"
                                                        >
                                                            <img
                                                                src="../assets/images/closes.svg"
                                                                alt=""
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- <td class="date-td text-right">{{ item.created_at.substring(0, 10)}}</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="realtime-board w-ba btm-finance-section">
                        <div class="realtime-nav dflex-ac-jc">
                            <button type="button" v-bind:class="{ active: tabSelecteds == 'tab3' }" v-on:click="changeTabs('tab3')">
                                {{ langweb("fe.tabco") }}
                            </button>
                            <button type="button" v-bind:class="{ active: tabSelecteds == 'tab4' }" v-on:click="changeTabs('tab4')">
                                {{ langweb("fe.tabci") }}
                            </button>
                        </div>
                        <div class="content w-ba">
                            <div
                                class="rolling-realtime withdraw-realtime active"
                                v-if="tabSelecteds === 'tab3'"
                            >
                                <marquee
                                    direction="up"
                                    scrolldelay="200"
                                    height="236px"
                                >
                                    <ul>
                                        <li
                                            class="tr item_center"
                                            v-for="(
                                                item, index
                                            ) in listCashouthome"
                                            v-bind:key="index"
                                        >
                                            <div class="user">
                                                <span>
                                                    {{ item.fullname }}
                                                </span>
                                            </div>
                                            <div class="amount">
                                                <span
                                                    >{{
                                                        new Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                                minimumFractionDigits: 0
                                                            }
                                                        ).format(item.amount)
                                                    }}
                                                    원</span
                                                >
                                            </div>
                                            <!--<div class="date">
                                                <span>{{ item.created_at }}</span>
                                            </div> -->
                                        </li>
                                    </ul>
                                </marquee>
                            </div>
                            <div
                                class="rolling-realtime deposit-realtime"
                                v-if="tabSelecteds === 'tab4'"
                            >
                                <marquee
                                    direction="up"
                                    duration="6000"
                                    height="236px"
                                >
                                    <ul>
                                        <li
                                            class="tr item_center"
                                            v-for="(
                                                items, indexs
                                            ) in listcashinhome"
                                            v-bind:key="indexs"
                                        >
                                            <div class="user">
                                                <span>
                                                    {{ items.fullname }}
                                                </span>
                                            </div>
                                            <div class="amount">
                                                <span
                                                    >{{
                                                        new Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                                minimumFractionDigits: 0
                                                            }
                                                        ).format(items.amount)
                                                    }}
                                                    원</span
                                                >
                                            </div>
                                            <!-- <div class="date">
                                                <span>{{ items.created_at }}</span>
                                            </div> -->
                                        </li>
                                    </ul>
                                </marquee>
                            </div>
                        </div>
                    </div>
                    <div class="board-panel notice">
                        <div class="header dflex-ac-jc w-b">
                            <div class="title-panel dflex-ac-jc mr-auto">
                                <div class="icon-panel">
                                    <img
                                        src="../assets/img/icons/announcement-icon.png"
                                        class="img_loahome"
                                    />
                                </div>
                                <span>{{ langweb("fe.notification") }}</span>
                            </div>
                            <button data_id="3" @click="showMain" class="more-btn notice-link dflex-ac-jc">전체보기</button>
                        </div>
                        <div class="content w-b btm-notice-section">
                            <table class="w-100">
                                <tbody>
                                    <tr
                                        v-for="(
                                            item, index
                                        ) in listnotifihome.slice(0, 5)"
                                        v-bind:key="index"
                                    >
                                        <td class="text-left">
                                            <a
                                                href="javascript:void(0);"
                                                v-if="token !== null"
                                                class="notifyhome_click"
                                                >{{
                                                    item.title.substring(0, 25)
                                                }}</a
                                            >
                                            <a
                                                href="javascript:void(0);"
                                                v-else
                                                @click="showModal = true"
                                                >{{
                                                    item.title.substring(0, 25)
                                                }}</a
                                            >
                                            <div
                                                class="substring_content_tbs hidepps"
                                                style="color: #fff"
                                            >
                                                <div class="flex_popup">
                                                    <div class="showtb">
                                                        <div
                                                            class="height_scroll"
                                                        >
                                                            <p
                                                                class="titlenoti"
                                                            >
                                                                {{ item.title }}
                                                            </p>
                                                            <div
                                                                v-html="
                                                                    item.content
                                                                "
                                                                class="content_tb"
                                                            ></div>
                                                        </div>
                                                        <button
                                                            class="close_notis"
                                                            type="button"
                                                        >
                                                            <img
                                                                src="../assets/images/closes.svg"
                                                                alt=""
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <!-- item game click slot -->
            <div class="root-cash popupItemGame" v-if="token !== null">
                <div class="wraper-cash">
                    <div class="cash-tab">
                        <div class="tabcash">
                            <div class="titmenu_popup">
                                {{ langweb("fe.text1") }}
                                <img src="../assets/images/right.svg" alt="" />
                            </div>
                            <ul>
                                <li>
                                    <form
                                        action=""
                                        method=""
                                        name="frm_search"
                                        id="_frm_search"
                                    >
                                        <div class="input_search">
                                            <input
                                                type="text"
                                                v-model="search"
                                                class="key_search"
                                                placeholder="Search"
                                                @keyup="searchGame"
                                            />
                                        </div>
                                    </form>
                                </li>
                                <li
                                    v-bind:class="{
                                        active: tabItemGame == 'taba'
                                    }"
                                    v-on:click="changeTabItemGame('taba')"
                                >
                                    <a>
                                        <font-awesome-icon
                                            icon="fa-solid fa-clover"
                                        />&nbsp; {{ langweb("fe.text3") }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="root-content-cash-tab">
                            <div class="content-tab-cashin">
                                <div class="taba" v-if="tabItemGame === 'taba'">
                                    <div class="text_category">
                                        <div>
                                            <font-awesome-icon
                                                icon="fa-solid fa-clover"
                                            />&nbsp;
                                            <span class="namegaming"></span>
                                        </div>
                                    </div>
                                    <div
                                        class="root_main_cash dflex-ac-jc w-100 wrapper_tab_game"
                                    >
                                        <a
                                            href="javascript:void(0);"
                                            @click="
                                                getGameProcess(
                                                    item.vender,
                                                    item.code,
                                                    2
                                                )
                                            "
                                            class="itemgamePP"
                                            v-for="(item, index) in datalt"
                                            v-bind:key="index"
                                        >
                                            <div class="wp-itemgpp">
                                                <img
                                                    class="g-img"
                                                    :src="item.image"
                                                />
                                                <div class="nameitem_game">
                                                    <span>{{
                                                        item.title
                                                    }}</span>
                                                </div>
                                            </div>
                                        </a>
                                        <paginate
                                            class="container_paginater"
                                            @update:modelValue="clickCallbacklt"
                                            :totalCount="totalCountlt"
                                            :limit="perPagelt"
                                            v-model="pagelt"
                                        ></paginate>
                                    </div>
                                </div>
                                <div class="tabb" v-if="tabItemGame === 'tabb'">
                                    <div class="text_category">
                                        <div>
                                            <font-awesome-icon
                                                icon="fa-solid fa-bomb"
                                            />&nbsp; {{ langweb("fe.text4") }}
                                        </div>
                                    </div>
                                    <div
                                        class="root_main_cash dflex-ac-jc w-100 wrapper_tab_game"
                                    >
                                        {{ listGamingsearch }}
                                        <div
                                            class="card"
                                            v-for="(
                                                post, index
                                            ) in listGamingsearch"
                                            :key="index"
                                        >
                                            <a>
                                                {{ post.title }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img
                            src="../assets/images/closes.svg"
                            alt=""
                            @click="closePopupItemGame"
                            class="closetab"
                        />
                    </div>
                </div>
            </div>
            <!-- end item game click slot -->
        </main>
        <div id="modal-example" class="modal-vue">
            <div
                class="overlay"
                v-if="showModal"
                @click="showModal = false"
            ></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal">
                    <div class="header-modal">
                        <div class="title-header">
                            {{ langweb("fe.notification") }}
                        </div>
                        <button class="close-modal" @click="showModal = false">
                            x
                        </button>
                    </div>
                    <div class="content-modal">
                        <div class="text-content">
                            {{ langweb("fe.texts") }}
                        </div>
                    </div>
                    <div class="footer-modal">
                        <button class="config-modal" @click="showModal = false">
                            {{ langweb("fe.ok") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal maintenance game -->
        <div id="modal-example" class="modal-vue">
            <div
                class="overlay"
                v-if="showModal_bt"
                @click="showModal_bt = false"
            ></div>
            <div class="modal" v-if="showModal_bt">
                <div class="wp-modal">
                    <div class="header-modal">
                        <div class="title-header">
                            {{ langweb("fe.notification") }}
                        </div>
                        <button
                            class="close-modal"
                            @click="showModal_bt = false"
                        >
                            x
                        </button>
                    </div>
                    <div class="content-modal">
                        <div class="text-content">
                            {{ langweb("fe.maintenance") }}
                        </div>
                    </div>
                    <div class="footer-modal">
                        <button
                            class="config-modal"
                            @click="showModal_bt = false"
                        >
                            {{ langweb("fe.ok") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End modal maintenance game -->
    </div>

    <div
        v-if="$isMobile()"
        id="popupID1736"
        class="popup_window"
        style="width: 350px; height: 350px; top: 0px; left: 0px; display: none"
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop1.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 350px;
                                height: 350px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1736')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1736')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-else
        id="popupID1736"
        class="popup_window"
        style="
            width: 450px;
            height: 600px;
            top: 300px;
            left: 50px;
            display: none;
        "
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop1.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 450px;
                                height: 600px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1736')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1736')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div
        v-if="$isMobile()"
        id="popupID1737"
        class="popup_window"
        style="width: 350px; height: 350px; top: 0px; left: 0px; display: none"
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop2.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 350px;
                                height: 350px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1737')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1737')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-else
        id="popupID1737"
        class="popup_window"
        style="
            width: 450px;
            height: 600px;
            top: 300px;
            left: 500px;
            display: none;
        "
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop2.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 450px;
                                height: 600px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1737')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1737')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div
        v-if="$isMobile()"
        id="popupID1738"
        class="popup_window"
        style="width: 350px; height: 350px; top: 0px; left: 0px; display: none"
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop3.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 350px;
                                height: 350px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1738')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1738')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-else
        id="popupID1738"
        class="popup_window"
        style="
            width: 450px;
            height: 600px;
            top: 300px;
            left: 950px;
            display: none;
        "
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop3.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 450px;
                                height: 600px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1738')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1738')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div
        v-if="$isMobile()"
        id="popupID1739"
        class="popup_window"
        style="width: 350px; height: 350px; top: 0px; left: 0px; display: none"
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop4.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 350px;
                                height: 350px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1739')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1739')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-else
        id="popupID1739"
        class="popup_window"
        style="
            width: 450px;
            height: 600px;
            top: 300px;
            left: 1400px;
            display: none;
        "
    >
        <table
            width="100%"
            height="100%"
            style="border: 1px solid #eaeaea; background-color: white"
        >
            <tbody style="vertical-align: middle">
                <tr>
                    <td>
                        <img
                            src="../assets/images/pop4.png"
                            alt="popup"
                            style="
                                vertical-align: middle;
                                width: 450px;
                                height: 600px;
                                max-width: 100%;
                                max-height: 100%;
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        height="22"
                        align="right"
                        valign="middle"
                        bgcolor="#D5D5D5"
                    >
                        <div align="right" style="width: 100%">
                            <input
                                type="checkbox"
                                name="chkbox"
                                v-on:click="hidePopup('popupID1739')"
                            />
                            <font size="1" color="#000000"
                                >오늘 하루 이창을 열지않음</font
                            >
                            <a
                                href="javascript:void(0);"
                                @click="popupClose('popupID1739')"
                                ><b
                                    ><font size="2" color="#000000"
                                        >[닫기]</font
                                    ></b
                                ></a
                            >
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import $ from "jquery";
import { mapState } from 'vuex';
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

$(document).ready(function () {
    $(document).on("click", ".notifyhome_click", function () {
        $(this).parent().find(".substring_content_tbs").show();
        $(".toggle-section").css("z-index", "-1");
        $(".footer-section").css("z-index", "-1");
        $("body").css("overflow", "hidden");
    });
    $(document).on("click", ".close_notis", function () {
        $(this).parents(".substring_content_tbs").hide();
        $(".toggle-section").css("z-index", "0");
        $(".footer-section").css("z-index", "0");
        $("body").css("overflow", "initial");
    });
    $(document).on("click", ".notifyhome_clicks", function () {
        $(this).parent().find(".substring_content_tbss").show();
        $(".toggle-section").css("z-index", "-1");
        $(".footer-section").css("z-index", "-1");
        $("body").css("overflow", "hidden");
    });
    $(document).on("click", ".close_notiss", function () {
        $(this).parents(".substring_content_tbss").hide();
        $(".toggle-section").css("z-index", "0");
        $(".footer-section").css("z-index", "0");
        $("body").css("overflow", "initial");
    });
});

export default {
    name: "Home_city",
    setup() {
        const store = useStore();
        const lang = computed(() => store.state.lang);
        const lang_en = computed(() => store.state.language);
        store.dispatch("changLang");
        return {
            langweb(variable) {
                lang.value.filter(function (e) {
                    if (e.variable == variable) {
                        if (lang_en.value == "en") {
                            variable = e.en;
                        } else {
                            variable = e.kr;
                        }
                    }
                });
                return variable;
            }
        };
    },
    computed: {
        ...mapState(['gameTab']),
    },
    data() {
        return {
            tabSelected: "tab1",
            tabSelecteds: "tab3",
            showModal: false,
            showModal_bt: false,
            hoveredIndex: null,
            listchat: [],
            token: window.localStorage.getItem("token"),
            listCashouthome: [],
            listcashinhome: [],
            listnotifihome: [],
            listGameCasino: [],
            listGameSlot: [],
            modalcoin: false,
            id_user: "1",
            title: "",
            question_content: "",
            listtitlehome: [],
            tabItemGame: "taba",
            itemgamepp: [],
            pagelt: 1,
            perPagelt: 35,
            totalCountlt: 0,
            datalt: [],
            listSearch: [],
            search: "",
            listGaming: [],
            listGamingsearch: [],
            timeout_icon: null,
            listFaq: [],
            banners: [
                {
                    imgURL: require("@/assets/img/banner/banner-1.png")
                },
                {
                    imgURL: require("@/assets/img/banner/banner-2.png")
                }
            ],
            selectedPopOver:null,
            odometer: null,
        };
    },
    components: {
        Carousel,
        Slide
    },
    // mounted(){
    //     setTimeout(()=>{
    //         odometer.innerHTML = 456;
    //     }, 1000);
    // },
    created() {
        if (this.token !== null) {
            this.getListnotifiHome();
            this.getListFaq();
        } else {
            this.getListFaqLogin();
            this.getListnotifiHomelist();
        }

        this.getListcashoutHome();
        this.getListcashinHome();
        this.getListGameCasino();
        this.getListGameSlot();

        // console.log(this.$cookies.get('aabb'));
    },
    methods: {
        showPopup(name) {
            if (this.$cookies.get(name) == "popup") {
                document.getElementById(name).style.display = "none";
            } else {
                document.getElementById(name).style.display = "block";
            }
        },
        hidePopup(name) {
            this.$cookies.set(name, "popup", "1d");
            document.getElementById(name).style.display = "none";
        },
        popupClose(e) {
            document.getElementById(e).style.display = "none";
        },
        dragElement(t) {
            var a = 0,
                n = 0,
                i = 0,
                o = 0;
            function e(e) {
                (e = e || window.event),
                    (i = e.clientX),
                    (o = e.clientY),
                    (document.onmouseup = l),
                    (document.onmousemove = s);
            }
            function s(e) {
                (e = e || window.event),
                    (a = i - e.clientX),
                    (n = o - e.clientY),
                    (i = e.clientX),
                    (o = e.clientY),
                    (t.style.top = t.offsetTop - n + "px"),
                    (t.style.left = t.offsetLeft - a + "px");
            }
            function l() {
                (document.onmouseup = null), (document.onmousemove = null);
            }
            document.getElementById(t.id + "header")
                ? (document.getElementById(t.id + "header").onmousedown = e)
                : (t.onmousedown = e);
        },
        changeTab(tab) {
            this.tabSelected = tab;
        },
        changeTabs(tab) {
            this.tabSelecteds = tab;
        },
        changeTabItemGame(tab) {
            this.tabItemGame = tab;
        },
        getListcashoutHome() {
            // var token_user = window.localStorage.getItem('token')
            // this.axios.get(this.api_listCo, {
            //     headers: {
            //         Authorization: 'Bearer ' + token_user
            //     }
            // }).then((response) => {
            //     this.listCashouthome = response.data.data
            // })
            let today = new Date();
            let date =
                today.getDate() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getFullYear();
            this.listCashouthome = [
                { fullname: "aa***", amount: "1000000", created_at: date },
                { fullname: "db***", amount: "2000000", created_at: date },
                { fullname: "hg***", amount: "3500000", created_at: date },
                { fullname: "tg***", amount: "800000", created_at: date },
                { fullname: "wj***", amount: "1000000", created_at: date },
                { fullname: "qb***", amount: "3000000", created_at: date },
                { fullname: "mk***", amount: "500000", created_at: date },
                { fullname: "sx***", amount: "1000000", created_at: date },
                { fullname: "zj***", amount: "4000000", created_at: date },
                { fullname: "zj***", amount: "2000000", created_at: date }
            ];
        },
        getListcashinHome() {
            // var token_user = window.localStorage.getItem('token')
            // this.axios.get(this.api_listCi, {
            //     headers: {
            //         Authorization: 'Bearer ' + token_user
            //     }
            // }).then((response) => {
            //     this.listcashinhome = response.data.data
            // })
            let today = new Date();
            let date =
                today.getDate() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getFullYear();
            this.listcashinhome = [
                { fullname: "nm***", amount: "500000", created_at: date },
                { fullname: "vc***", amount: "300000", created_at: date },
                { fullname: "bf***", amount: "1000000", created_at: date },
                { fullname: "pl***", amount: "800000", created_at: date },
                { fullname: "qy***", amount: "500000", created_at: date },
                { fullname: "io***", amount: "3000000", created_at: date },
                { fullname: "ka***", amount: "5000000", created_at: date },
                { fullname: "ok***", amount: "1000000", created_at: date },
                { fullname: "xc***", amount: "800000", created_at: date },
                { fullname: "dh***", amount: "1500000", created_at: date }
            ];
        },
        getListnotifiHome() {
            var token_user = window.localStorage.getItem("token");
            this.axios
                .get(this.api_listnotifi, {
                    headers: {
                        Authorization: "Bearer " + token_user
                    }
                })
                .then((response) => {
                    this.listnotifihome = response.data.data;
                });
        },
        getListnotifiHomelist() {
            this.axios.get(this.api_listnotifilist, {}).then((response) => {
                this.listnotifihome = response.data.data;
            });
        },
        getListFaq() {
            var token_user = window.localStorage.getItem("token");
            this.axios
                .get(this.api_listFaq, {
                    headers: {
                        Authorization: "Bearer " + token_user
                    }
                })
                .then((response) => {
                    this.listFaq = response.data.data.list_data;
                });
        },
        getListFaqLogin() {
            this.axios.get(this.api_listFaq_login, {}).then((response) => {
                this.listFaq = response.data.data.list_data;
            });
        },
        getListGameCasino() {
            this.axios.get(this.api_gamecasino).then((response) => {
                this.listGameCasino = response.data.data.filter(
                    (d) => d.type === "casino"
                );
                // console.log(this.listGameCasino);

                // //this.showPopup('popupID1736');
                // //this.showPopup('popupID1737');
                // // this.showPopup('popupID1738');
                // //this.showPopup('popupID1739');
            });
        },
        getListGameSlot () {
            this.axios.get(this.api_gamecasino).then((response) => {
                // console.log(response.data.data);
                this.listGameSlot = response.data.data.filter(d => d.type === 'slot')
            })
        },
        // listTitle () {
        //     var token_user = window.localStorage.getItem('token')
        //     this.axios.get(this.api_listtitle, {
        //         headers: {
        //             Authorization: 'Bearer ' + token_user
        //         }
        //     }).then((response) => {
        //         this.listtitlehome = response.data.data.filter(d => d.type == 1)
        //         this.title = this.listtitlehome[0]?.id
        //     })
        // },
        getGameProcess(api, code, type) {
            $(".loading_show").show().fadeIn(10);
            var token_user = window.localStorage.getItem("token");
            this.axios
                .post(
                    this.api_liveprocess,
                    {
                        gamecode: code,
                        gametype: type,
                        gameapi: api
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + token_user
                        }
                    }
                )
                .then((response) => {
                    if (response.data.data.result == 1) {
                        var size = {
                            width:
                                window.innerWidth || document.body.clientWidth,
                            height:
                                window.innerHeight || document.body.clientHeight
                        };
                        //창 크기 지정
                        var width = size.width;
                        var height = size.height;
                        //pc화면기준 가운데 정렬
                        var left = window.screen.width / 2 - width / 2;
                        var top = window.screen.height / 4;
                        //윈도우 속성 지정
                        var windowStatus =
                            "width=" +
                            width +
                            ", height=" +
                            height +
                            ", left=" +
                            left +
                            ", top=" +
                            top +
                            ", scrollbars=yes, status=yes, resizable=yes, titlebar=yes";
                        //연결하고싶은url
                        const url = response.data.data.url;
                        //등록된 url 및 window 속성 기준으로 팝업창을 연다.
                        window.open(url, "gamepopup", windowStatus);
                        $("#loading_show").css("display", "none");
                    } else {
                        this.$swal({
                            position: "top-end",
                            icon: "error",
                            title: response.data.data.url,
                            showConfirmButton: false,
                            timer: 3000
                        });
                        $("#loading_show").css("display", "none");
                    }
                });
        },
        showItemGame(event) {
            $(".loading_show").show().fadeIn(10);
            var dataid = event.target.getAttribute("data_id");
            var dataname = event.target.getAttribute("data_name");
            $(".datacode").val(dataid);
            $(".namegaming").text(dataname);
            let codeid = $(".datacode").val();
            $(".popupItemGame").show();
            $(".wraper-root").addClass("z_index_in");
            $("body").css("overflow", "hidden");

            var token_user = window.localStorage.getItem("token");
            this.axios
                .get(this.api_listgameSlot + codeid, {
                    headers: {
                        Authorization: "Bearer " + token_user
                    }
                })
                .then((response) => {
                    this.listGaming = response.data.data;
                    this.itemgamepp = response.data.data;
                    this.totalCountlt = this.itemgamepp.length;
                    this.paginatedDatalt();
                    $("#loading_show").css("display", "none");
                });
        },
        paginatedDatalt() {
            // console.log('this.itemgamepp',this.itemgamepp);
            this.datalt = this.itemgamepp.slice(
                (this.pagelt - 1) * this.perPagelt,
                this.pagelt * this.perPagelt
            );
        },
        clickCallbacklt(pageNum) {
            this.pagelt = pageNum;
            this.paginatedDatalt();
        },
        closePopupItemGame() {
            $(".popupItemGame").hide();
            $(".wraper-root").removeClass("z_index_in");
            $("body").css("overflow", "initial");
            this.datalt = [];
        },
        gameHover(index) {
            this.hoveredIndex = index;
            $(".sc-btn").addClass("inactive");
        },
        gameHoverRemove() {
            this.hoveredIndex = null;
            $(".sc-btn").removeClass("inactive");
        },
        searchGame(e) {
            e.preventDefault();
            let keyname = $(".key_search").val();
            let act_length = this.listGaming.length;
            let dataArray = [];
            let set = 0;
            $.each(this.listGaming, function (i) {
                if (i + 1 == act_length) {
                    set = 1;
                }
                if (
                    this.title.indexOf(keyname) != -1 ||
                    this.code.indexOf(keyname) != -1
                ) {
                    return dataArray.push(this);
                }
                if (set == 1) {
                    // console.log('action: ', dataArray);
                    // return dataArray
                    // this.listGamingsearch = dataArray
                    // this.totalCountlt = this.itemgamepp.length
                    // this.paginatedDatalt()
                    // this.datalt = dataArray
                    // console.log('action: ', this.listGamingsearch);
                }
            });
            this.itemgamepp = dataArray;
            this.totalCountlt = this.itemgamepp.length;
            this.paginatedDatalt();
            // this.listGamingsearch = dataArray
            // console.log('dataArray',dataArray);
        },
        getMaintenance() {
            this.showModal_bt = true;
        }
    }
};
</script>
<style>
</style>
